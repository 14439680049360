::-webkit-input-placeholder,::placeholder{
  font-size:9pt;
}

/* ---------------- styles ------------------------------------------------ */

h1 {
	font-size:24pt;
	text-align:center;
}

/* --------------------- navbar style ------------------------------------- */

/* https://work.smarchal.com/twbscolor/ */

.navbar {
  background-color: #ffffff;
}
.navbar .navbar-brand {
  color: #868686;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: #999999;
}
.navbar .navbar-text {
  color: #868686;
}
.navbar .navbar-text a {
  color: #999999;
}
.navbar .navbar-text a:hover,
.navbar .navbar-text a:focus {
  color: #999999; 
}
.navbar .navbar-collapse .nav-link,span {
  color: #aaa;
  border-radius: .25rem;
  margin: 0 0;
  cursor:'pointer';
}
.navbar .navbar-nav .nav-link:not(.disabled):hover,
.navbar .navbar-nav .nav-link:not(.disabled):focus {
  color: #999999;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus,
.navbar .navbar-nav .nav-item.show .nav-link,
.navbar .navbar-nav .nav-item.show .nav-link:hover,
.navbar .navbar-nav .nav-item.show .nav-link:focus {
  color: #999999;
  background-color: #d7d7d7;
}
.navbar .navbar-toggle {
  border-color: #d7d7d7;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: #d7d7d7;
}
.navbar .navbar-toggle .navbar-toggler-icon {
  color: #868686;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
  border-color: #868686;
}
.navbar .navbar-link {
  color: #868686;
}
.navbar .navbar-link:hover {
  color: #999999;
}

@media (max-width: 575px) {
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #868686;
  }
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #999999;
  }
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #999999;
    background-color: #d7d7d7;
  }
}

@media (max-width: 767px) {
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #868686;
  }
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #999999;
  }
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #999999;
    background-color: #d7d7d7;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #868686;
  }
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #999999;
  }
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #999999;
    background-color: #d7d7d7;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #868686;
  }
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #999999;
  }
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #999999;
    background-color: #d7d7d7;
  }
}

.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item {
  color: #868686;
}
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:hover,
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:focus {
  color: #999999;
}
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item.active {
  color: #999999;
  background-color: #d7d7d7;
}
